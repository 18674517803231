<template>
  <TitleBar :actions="[]" :collapseActions="false">
    <template #custom>
      <Button
        v-if="!displayDateFilters"
        class="mx-2"
        label="Select Dates"
        @click="displayDateFilters = true"
      />
    </template>
    {{ pageTitle }}</TitleBar
  >

  <div class="py-0 px-4">
    <div
      class="grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
    >
      <DataTable
        class="col-12"
        :value="report"
        responsiveLayout="scroll"
        :loading="isLoading"
        :paginator="true"
        :rows-per-page-options="rowSelectionOptions"
        v-model:rows="rowsToShow"
        :totalRecords="totalRecords"
        dataKey="id"
        resizableColumns
        columnResizeMode="expand"
        scrollable
        showGridlines
        ref="dt"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        paginator-template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      >
        <template #header>
          <div class="justify-content-between h-2prem">
            <Button
              type="button"
              class="p-button-primary mx-2"
              @click="openFilters"
              icon="pi pi-filter"
              label="Filter and Search"
            />
            <Button
              type="button"
              icon="pi pi-external-link"
              label="Export"
              @click="exportCSV($event)"
            />
            <span style="float: right">
              <Button
                type="button"
                class="p-button-primary mx-2"
                @click="toggleOptional"
                icon="pi pi-eye"
                label="Toggle Columns"
                aria:haspopup="true"
                aria-controls="overlay_panel"
              />

              <OverlayPanel ref="optionalPanel" appendTo="body">
                <div
                  v-for="column in allColumns"
                  :key="column.field"
                  class="flex-1 px-2 py-2"
                >
                  <Checkbox
                    name="column"
                    :value="column"
                    :binary="true"
                    v-model="column.toggle"
                  />
                  <label class="pl-2">{{ column.label }}</label>
                </div>
              </OverlayPanel>

              <Button
                type="button"
                class="p-button-primary mx-2"
                @click="toggleFrozen"
                icon="pi pi-lock"
                label="Toggle Locked"
                aria:haspopup="true"
                aria-controls="overlay_panel"
              />

              <OverlayPanel ref="frozenPanel" appendTo="body">
                <div
                  v-for="column in visibleColumns"
                  :key="column.field"
                  class="flex-1 px-2 py-2"
                >
                  <Checkbox
                    name="column"
                    :value="column"
                    :binary="true"
                    v-model="column.frozen"
                  />
                  <label class="pl-2">{{ column.label }}</label>
                </div>
              </OverlayPanel>
            </span>
          </div>
        </template>
        <Column
          v-for="column in visibleColumns"
          :key="column.field"
          :field="column.field"
          :header="column.label"
          :sortable="true"
          :style="getColumnStyle(column)"
          :frozen="column.frozen"
        >
          <template #body="{ data }">
            {{ getValueForColumn(data, column) }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog
    header="Filter Results"
    v-model:visible="displayFilters"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <div class="px-2 py-1">
      <div class="surface-card p-4 shadow-2 border-round p-fluid">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-12">
            <label for="company_name" class="font-medium text-900"
              >Region</label
            >
            <MultiSelect
              v-model="clonedFormFilters['region']"
              :options="regionOptions"
              placeholder="Select Region"
            />
            <p
              class="text-primary mt-2 text-xs"
              v-if="
                typeof clonedFormFilters['region'] !== 'undefined' &&
                clonedFormFilters['region'].length > 0
              "
              @click="clearFilters('region')"
            >
              Clear
            </p>
          </div>
          <div class="field mb-4 col-6">
            <label for="model" class="font-medium text-900">Provider</label>
            <MultiSelect
              v-model="clonedFormFilters['provider']"
              :options="providerOptions"
              placeholder="Select Provider"
            />
            <p
              class="text-primary mt-2 text-xs"
              v-if="
                typeof clonedFormFilters['provider'] !== 'undefined' &&
                clonedFormFilters['provider'].length > 0
              "
              @click="clearFilters('provider')"
            >
              Clear
            </p>
          </div>
          <div class="field mb-4 col-6">
            <label for="model" class="font-medium text-900"
              >Provider Model</label
            >
            <MultiSelect
              v-model="clonedFormFilters['provider_model']"
              :options="providerModelOptions"
              placeholder="Select Provider Model"
            />
            <p
              class="text-primary mt-2 text-xs"
              v-if="
                typeof clonedFormFilters['provider_model'] !== 'undefined' &&
                clonedFormFilters['provider_model'].length > 0
              "
              @click="clearFilters('provider_model')"
            >
              Clear
            </p>
          </div>
          <div class="field mb-4 col-12">
            <label for="model" class="font-medium text-900">Creative</label>
            <MultiSelect
              v-model="clonedFormFilters['creative']"
              :options="creativeOptions"
              placeholder="Select Creative"
            />
            <p
              class="text-primary mt-2 text-xs"
              v-if="
                typeof clonedFormFilters['creative'] !== 'undefined' &&
                clonedFormFilters['creative'].length > 0
              "
              @click="clearFilters('creative')"
            >
              Clear
            </p>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        @click="closeFilters(false)"
        class="p-button-text"
      />
      <Button
        label="Apply Filters"
        icon="pi pi-check"
        @click="closeFilters(true)"
        autofocus
      />
    </template>
  </Dialog>

  <Dialog
    header="Select Dates"
    v-model:visible="displayDateFilters"
    :style="{ width: '50vw' }"
    :modal="true"
  >
    <div class="px-2 py-1">
      <div class="surface-card p-4 shadow-2 border-round p-fluid">
        <div class="grid formgrid p-fluid">
          <div class="field mb-4 col-12 md:col-6">
            <label for="response_date" class="font-medium text-900"
              >In Home Date Range</label
            >
            <Calendar
              :showIcon="true"
              v-model="dateRange"
              selection-mode="range"
              :manual-input="false"
              date-format="mm-dd-yy"
            ></Calendar>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <Button
        :disabled="!filtersAreSet"
        label="Apply Filters"
        icon="pi pi-check"
        autofocus
        @click="runReport"
      />
    </template>
  </Dialog>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import DirectMailPerformance from "@/models/reports/DirectMailPerformance";
import { useAppStore } from "@/stores/app";
import { ref, computed } from "vue";

export default {
  components: {
    TitleBar,
  },
  setup() {
    const appStore = useAppStore();
    const displayDateFilters = ref(false);
    const displayFilters = ref(false);
    const optionalPanel = ref();
    const frozenPanel = ref();
    const dt = ref();
    const formFilters = ref({});
    const clonedFormFilters = ref({});
    const allColumns = ref([
      {
        field: "in_home_date",
        label: "InHome Date",
        toggle: true,
        frozen: false,
      },
      { field: "region", label: "Region", toggle: true, frozen: false },
      {
        field: "filename",
        label: "Filename",
        toggle: true,
        frozen: false,
        width: 500,
      },
      {
        field: "creative",
        label: "Creative",
        toggle: true,
        frozen: false,
        width: 500,
      },
      { field: "provider", label: "Provider", toggle: true, frozen: false },
      {
        field: "provider_model",
        label: "Model",
        toggle: true,
        frozen: false,
        width: 150,
      },
      {
        field: "total_mailed",
        label: "Total Mailed",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },
      {
        field: "total_leads",
        label: "Tot Leads",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },
      {
        field: "total_postcard_leads",
        label: "Tot PC Leads",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },
      {
        field: "rr",
        label: "RR",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },
      {
        field: "sold_volume",
        label: "Sold Vol",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },
      {
        field: "sold_postcard_volume",
        label: "Sold PC Vol",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },

      {
        field: "lead_to_close",
        label: "Lead to Close",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },
      {
        field: "sales_revenue",
        label: "Sales Rev",
        toggle: true,
        currencyFormat: true,
        frozen: false,
      },

      {
        field: "sales_postcard_revenue",
        label: "Sales PC Rev",
        toggle: true,
        currencyFormat: true,
        frozen: false,
      },
      {
        field: "rev_sale",
        label: "Rev Per Sale",
        toggle: true,
        currencyFormat: true,
        frozen: false,
      },
      {
        field: "total_spend",
        label: "Total Spend",
        toggle: true,
        currencyFormat: true,
        frozen: false,
      },
      {
        field: "nmc",
        label: "NMC",
        toggle: true,
        numberFormat: true,
        frozen: false,
      },
      {
        field: "cost_per_sale",
        label: "Cost Per Sale",
        toggle: true,
        currencyFormat: true,
        frozen: false,
      },
      {
        field: "cost_per_lead",
        label: "Cost Per Lead",
        toggle: true,
        currencyFormat: true,
        frozen: false,
      },
    ]);

    const toggleOptional = (event) => {
      optionalPanel.value.toggle(event);
    };
    const visibleColumns = computed(() => {
      let visibleCols = allColumns.value.filter((item) => item.toggle);
      visibleCols.sort((b, a) => a.frozen - b.frozen);
      return visibleCols;
    });

    const optionalColumns = computed(() =>
      allColumns.value.filter((item) => "toggle" in item)
    );

    const toggleFrozen = (event) => {
      frozenPanel.value.toggle(event);
    };

    const formatCurrency = (value) => {
      return value.toLocaleString("en-US", {
        currency: "USD",
      });
    };
    const formatNumber = (value) => {
      return value.toLocaleString("en-US", {});
    };

    const dateRange = ref(null);

    const openFilters = () => {
      console.log(formFilters.value);
      clonedFormFilters.value = { ...formFilters.value };
      console.log("ClonedFilters");
      console.log(clonedFormFilters.value);
      displayFilters.value = true;
    };

    const isLoading = ref(false);

    const runReport = async () => {
      isLoading.value = true;
      displayDateFilters.value = false;
      console.log(dateRange);
      let response = await DirectMailPerformance.where(
        "campaign_id",
        appStore.currentCampaign.id
      )
        .whereIn("dateRange", [dateRange.value])
        .setFilters(formFilters.value)
        .get();
      report.value = response.data;
      totalRecords.value = response.meta.total;

      isLoading.value = false;
    };

    const totalRecords = ref(0);

    const clearFilters = (filter) => {
      if (filter == "all") {
        //Reset All Filters
      } else {
        if (Array.isArray(clonedFormFilters.value[filter])) {
          clonedFormFilters.value[filter] = [];
        } else {
          clonedFormFilters.value[filter] = "";
        }
      }
    };

    const getValueForColumn = (data, column) => {
      if (column.currencyFormat) return formatCurrency(data[column.field]);
      if (column.numberFormat) return formatNumber(data[column.field]);
      else return data[column.field];
    };

    const report = ref(null);

    const closeFilters = (shouldUpdate) => {
      if (shouldUpdate) {
        formFilters.value = { ...clonedFormFilters.value };
        runReport();
      }
      displayFilters.value = false;
    };

    const getColumnStyle = (column) => {
      let style = "";
      if (column.currencyFormat)
        style += "text-align: right; justify-content:end";
      if (column.numberFormat)
        style += "text-align: right; justify-content:end";
      if (column.width) style += "min-width:" + column.width + "px;";
      return style;
    };

    const exportCSV = () => {
      dt.value.exportCSV();
    };

    return {
      appStore,
      displayFilters,
      displayDateFilters,
      optionalPanel,
      toggleOptional,
      optionalColumns,
      visibleColumns,
      allColumns,
      formatCurrency,
      formatNumber,
      getValueForColumn,
      getColumnStyle,
      dt,
      exportCSV,
      frozenPanel,
      toggleFrozen,
      clonedFormFilters,
      openFilters,
      runReport,
      isLoading,
      dateRange,
      report,
      closeFilters,
      clearFilters,
    };
  },
  data() {
    return {
      actions: [],

      pageTitle: "Direct Mail Performance",

      currentPage: 1,
      rowsToShow: 50,
      rowSelectionOptions: [10, 20, 50, 100, 500, 1000, 5000],
    };
  },
  methods: {},
  computed: {
    filtersAreSet() {
      return this.dateRange !== null;
    },
    regionOptions() {
      if (this.report) {
        return [...new Set(this.report.map((item) => item.region))].sort();
      }

      return [];
    },
    providerOptions() {
      if (this.report) {
        return [...new Set(this.report.map((item) => item.provider))].sort();
      }

      return [];
    },
    providerModelOptions() {
      if (this.report) {
        return [
          ...new Set(this.report.map((item) => item.provider_model)),
        ].sort();
      }

      return [];
    },
    creativeOptions() {
      if (this.report) {
        return [...new Set(this.report.map((item) => item.creative))].sort();
      }

      return [];
    },
  },
  async mounted() {
    if (!this.filtersAreSet) {
      this.displayDateFilters = true;
    }
    this.isLoading = false;
  },
};
</script>
