<template>
  <teleport to="#mr_title" v-if="isMounted">
    <div class="surface-card shadow-2 p-3">
      <div
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0"
      >
        <ul
          class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
        >
          <h2 class="m-0 p-0"><slot></slot></h2>
        </ul>
        <ul
          class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
          v-if="this.actions.length > 0"
        >
          <li v-if="!collapseActions">
            <Button
              v-for="action in actions"
              :key="action.link"
              class="ml-2"
              :label="action.label"
              @click="$router.push(action.link)"
            />
          </li>
          <li v-else>
            <Button
              class="p-button-text"
              label="More"
              @click="toggle"
              icon="pi pi-chevron-down"
              iconPos="right"
            />
            <OverlayPanel class="py-1" ref="op">
              <ul class="list-none pl-0 m-0">
                <li v-for="action in actions" :key="action.link">
                  <Button
                    class="ml-2 p-button-text"
                    :label="action.label"
                    @click="$router.push(action.link)"
                  />
                </li>
              </ul>
            </OverlayPanel>
          </li>
        </ul>
        <div class="flex justify-content-end" v-if="hasCustomActions">
          <slot name="custom"></slot>
        </div>
      </div>
    </div>
  </teleport>
  <teleport
    to="#mr_footer"
    v-if="(actions.length > 0 || hasCustomActions) && isMounted"
  >
    <div class="surface-card shadow-2 p-3">
      <div
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0"
      >
        <ul
          class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
        ></ul>
        <ul
          class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"
          v-if="this.actions.length > 0"
        >
          <li v-if="!collapseActions">
            <Button
              v-for="action in actions"
              :key="action.link"
              class="ml-2"
              :label="action.label"
              @click="$router.push(action.link)"
            />
          </li>
          <li v-else>
            <Button
              class="p-button-text"
              label="More"
              @click="toggle"
              icon="pi pi-chevron-up"
              iconPos="right"
            />
            <OverlayPanel class="py-1" ref="op">
              <ul class="list-none pl-0 m-0">
                <li v-for="action in actions" :key="action.link">
                  <Button
                    class="ml-2 p-button-text"
                    :label="action.label"
                    @click="$router.push(action.link)"
                  />
                </li>
              </ul>
            </OverlayPanel>
          </li>
        </ul>
        <div class="flex justify-content-end" v-if="hasCustomActions">
          <slot name="custom"></slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "TitleBar",
  data() {
    return {
      isMounted: false,
    };
  },
  props: {
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    collapseActions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
  computed: {
    hasCustomActions() {
      return !!this.$slots["custom"];
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped></style>
